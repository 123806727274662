import React from "react";
import Mes from "./Mes";
import OutMes from "./OutMes";

const Ws = () => {
  return (
    <div className="container text-center">
      <Mes />
      {/* <Buttons /> */}
      <OutMes />
    </div>
  );
};

export default Ws;
